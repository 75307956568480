<template>
  <section>
    <Card :loading="loading.get">
      <template #header>
        <StepCardHeader
          v-model="current"
          :steps="steps"
          :isParent="false"
          :onlyFirstAllowed="false"
        />
      </template>
      <RolesPermissionsStep v-if="current == 1" />
      <DeliverySettingsStep v-if="current == 2" :settings="oSettings" />
      <BlockSettings v-if="current == 3" :settings="oSettings" />
      <VREmailSettings v-if="current == 4" :settings="oSettings" />
    </Card>
  </section>
</template>

<script>
import Card from '@/components/Card.vue';
// import AppSettingsStep from './Steps/AppSettings/index.vue';
import StepCardHeader from '@/components/StepCardHeader.vue';
import DeliverySettingsStep from './Steps/DeliverySettings';
import RolesPermissionsStep from './Steps/RolesPermissions';
import BlockSettings from './Steps/BlockSettings';
import VREmailSettings from './Steps/VREmailSettings';

export default {
  components: {
    Card,
    // AppSettingsStep,
    DeliverySettingsStep,
    RolesPermissionsStep,
    StepCardHeader,
    BlockSettings,
    VREmailSettings
  },
  async created() {
    if (this.$route.query.current) this.current = this.$route.query.current;
    this.getSettings();
  },
  data() {
    return {
      loading: { get: false, save: false },
      steps: [
        { name: 'global.roles' },
        { name: 'global.deliverySettings' },
        { name: 'global.blockSettings' },
        { name: 'global.virEmailSettings' }
      ],
      current: this.$route.meta?.current || 1,
      oSettings: {}
    };
  },
  computed: {},
  methods: {
    async getSettings() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(`/system/settings`);
        this.oSettings = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    onChangeStep(step = 1) {
      const routes = ['/roles-permissions', '/delivery-settings', '/app-settings'];
      this.$router.replace({
        path: `/advanced-settings/${routes[step - 1]}`
      });
      this.current = step;
    }
  }
};
</script>
<style lang="sass" scoped>
.card
  margin: 0
  min-height: calc(100% - 90px)
  ::v-deep
    .card-header
      padding: 0
      justify-content: flex-end
  .disabled
    cursor: not-allowed
</style>
