<template>
  <div class="permission-component flex f-column">
    <div id="header" class="flex f-jsb f-ai mb-2">
      <div v-if="loading">
        <b-icon icon="loading" custom-class="mdi-spin" />
        <FormatText capitalize class="header-card">
          {{ data[0].group_route }}
        </FormatText>
      </div>
      <b-checkbox
        v-else
        @input="selectAll(data)"
        v-model="checkedAll"
        :indeterminate="isIndeterminate"
        :disabled="!hasPermission || isAdmin"
      >
        <FormatText capitalize class="header-card">
          {{ formatSpace(data[0].group_route) }}
        </FormatText>
      </b-checkbox>
    </div>
    <div class="card-permission">
      <div class="rows" v-for="(item, index) in data" :key="index">
        <div class="flex f-jsb f-ai">
          <div class="flex f-full f-ai f-jci mx-2">
            <b-checkbox
              v-model="checkboxGroup"
              :native-value="item.id"
              @input="onInput(item)"
              :disabled="loading || isAdmin || !hasPermission"
            >
              <FormatText capitalize class="check-item">
                {{ item.description }}
              </FormatText>
            </b-checkbox>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { FormatText } from '@/components';
import { toast } from '@/utils/dialog';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    FormatText
  },
  data() {
    return {
      checkedAll: false,
      checkboxGroup: this.routesActive ? this.routesActive : [],
      loading: false
    };
  },
  computed: {
    isIndeterminate() {
      let value = null;
      let count = null;

      this.data.find((element) => {
        if (this.checkboxGroup.find((elementCheck) => elementCheck === element.id)) {
          count += 1;
        }
      });

      if (this.data.length !== count) {
        this.data.find((element) => {
          if (this.checkboxGroup.find((elementCheck) => elementCheck === element.id)) {
            value = true;
          }
        });
      }

      if (this.data.length === count) {
        this.allGroup();
      }

      return value;
    },
    Permission() {
      return Permissions.Rol_Permissions;
    },
    hasPermission() {
      let isAllowed = false;
      if (this.Permission.update?.length) {
        isAllowed = this.Permission.update.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(this.Permission.update);
    },
    isAdmin() {
      const { userType } = this;
      return userType.id === 1 || userType.name === 'Admin';
    }
  },
  methods: {
    formatSpace(group_route) {
      if (group_route === 'FundingSources') {
        return 'Funding Sources';
      }
      return this.data[0].group_route;
    },
    allGroup() {
      this.checkedAll = true;
    },
    async onInput(value) {
      if (!this.checkboxGroup.find((id) => id === value.id)) {
        if (!this.isNew) {
          await this.Api.patch(
            `/catalogs/user_roles/${this.userType.id}/routes/${value.id}/toggle`
          );
          this.$emit('setDataRol', { value: value.id, addElement: false });

          toast('success', this.$t('routes.delete'), 5000);
        } else {
          this.$emit('setDataNewRol', value.id, false);
        }
      } else {
        if (!this.isNew) {
          await this.Api.patch(
            `/catalogs/user_roles/${this.userType.id}/routes/${value.id}/toggle`
          );
          this.$emit('setDataRol', { value: value.id, addElement: true });

          toast('success', this.$t('routes.saved'), 5000);
        } else {
          this.$emit('setDataNewRol', value.id, true);
        }
      }
    },
    async selectAll(data) {
      if (this.checkedAll) {
        this.loading = true;

        const addPromise = new Promise((resolve) => {
          data.map(async (item) => {
            if (!this.checkboxGroup.find((id) => id === item.id)) {
              if (!this.isNew) {
                await this.Api.patch(
                  `/catalogs/user_roles/${this.userType.id}/routes/${item.id}/toggle`
                );
                this.$emit('setDataRol', { value: item.id, addElement: true });
              } else {
                this.$emit('setDataNewRol', item.id, true);
              }

              this.checkboxGroup = [...this.checkboxGroup, item.id];
            }
          });
          resolve(true);
        });

        Promise.all([addPromise]).then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });

        if (!this.isNew) toast('success', this.$t('routes.saved'), 5000);
      } else {
        this.loading = true;

        const deletePromise = new Promise((resolve) => {
          data.map(async (item) => {
            if (this.checkboxGroup.find((id) => id === item.id)) {
              if (!this.isNew) {
                await this.Api.patch(
                  `/catalogs/user_roles/${this.userType.id}/routes/${item.id}/toggle`
                );
                this.$emit('setDataRol', { value: item.id, addElement: false });
              } else {
                this.$emit('setDataNewRol', item.id, false);
              }

              this.checkboxGroup = this.checkboxGroup.filter((element) => element !== item.id);
            }
          });
          resolve(true);
        });

        Promise.all([deletePromise]).then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });

        if (!this.isNew) toast('success', this.$t('routes.delete'), 5000);
      }
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    userType: {
      type: Object,
      required: false,
      default: () => ({ id: null, name: null })
    },
    routesActive: {
      type: Array,
      required: false
    },
    isNew: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>

<style lang="sass" scoped>
.card-permission
  height: 100%
  overflow: auto
  .card-permission::-webkit-scrollbar
    display: none
.text-permission
  inline-size: 80%
  overflow-wrap: break-word
.permission-component
  margin: 1rem 0 1rem 0rem
  width: 300px
  border-radius: 8px 8px 0 0
  border: 1px solid $gray-200
  #header
    border-top-right-radius: 0.5rem
    border-top-left-radius: 0.5rem
    padding: 1rem
    background: $main-background
    border-bottom: 1px solid $gray-200
    .columns p
      margin: auto .5rem
    .header-card
      font-weight: 700
      color: $gray-700
  .rows
    padding: 0.5rem 0rem 0.5rem 0rem
    .b-checkbox
      margin: auto .4rem
    hr
      width: 100%
      height: 1px
      background: $gray-200
      margin: 1rem .75rem 0rem 0rem
</style>

<style lang="sass">
.dark
  .permission-component
    background-color: $dark-500
    #header .header-card, .check-item
      color: $gray-100
</style>
