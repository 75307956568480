<template>
  <div id="roles">
    <p class="sub-title">{{ $t('role.select') }}</p>
    <div v-if="loading">
      <b-skeleton height="50" width="300" :count="7" />
    </div>
    <Role-item
      v-else
      v-for="item in userTypes"
      :key="item.id"
      :item="item"
      :activeClass="userType.id == item.id"
      @click="$emit('click', $event)"
    />
  </div>
</template>
<script>
import RoleItem from '@/components/AdvancedSettings/RolesPermissions/RoleItem.vue';
export default {
  components: {
    RoleItem
  },
  created() {
    this.getUserTypes();
  },
  data() {
    return { loading: false, userTypes: [] };
  },
  methods: {
    async getUserTypes() {
      try {
        this.loading = true;
        const { data } = await this.Api.get('user_types?per_page=99999');
        this.userTypes = data;
      } catch (error) {
        console.error(error);
      }

      this.$emit('click', this.userTypes[0]);

      this.loading = false;
    }
  },
  props: {
    userType: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
#roles
  overflow-y: auto
  margin-left: 1rem
  min-width: 260px
  width: 260px
  height: calc(100vh - 100px)
  height: 100%
  .sub-title
    color: $gray-800
    font-weight: bold
  .b-skeleton
    margin-top: 1rem
.active
    border-color: red
    background: lighten($primary,41)
</style>

<style lang="sass">
.dark
  #roles
    .sub-title
      color: $gray-100
</style>
