<template>
  <div id="roles-permissions" class="flex">
    <Card id="permissions">
      <template #header>
        <div class="flex f-jsb f-ai">
          <b-icon v-if="loading" icon="loading" custom-class="mdi-spin" />
          <p v-else>{{ userType.name }}</p>
          <Input-search class="mb-1" :placeholder="$t('global.searchModule')" v-model="search" />
        </div>
      </template>
      <b-loading v-if="loading" :is-full-page="false" v-model="loading" />
      <div v-else class="flex f-wrap is-justify-content-space-between card-permission">
        <Permission
          class="permission"
          v-for="(item, index) in filterData"
          :userType="userType"
          :data="item"
          :key="index"
          :routesActive="routesActive"
          @setDataRol="$emit('setDataRol', $event)"
        />
        <Empty v-if="!data" />
      </div>
    </Card>
  </div>
</template>

<script>
import { Card, InputSearch, Empty } from '@/components';
import Permission from '@/components/AdvancedSettings/RolesPermissions/Permission.vue';

export default {
  components: {
    Card,
    InputSearch,
    Permission,
    Empty
  },
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filterData() {
      const data = this.data;
      const searchValue = this.search;
      let value = {};

      const compareValue = (str, query) => {
        str = String(str).toLowerCase();
        query = String(query).toLowerCase();
        return str.indexOf(query) > -1;
      };

      Object.keys(data).map(function (key, index) {
        if (compareValue(data[key][0].group_route, searchValue)) {
          value[index] = data[key];
        }
      });

      return value;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    userType: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    routesActive: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
#roles-permissions
  width: 100%
  .card
    min-width: 100%
    flex-grow: 1
    padding-right: 10px
    margin-right: 0px
    #permissions
      width: 100%
      height: 60% !important
    ::v-deep
      .card-content,
      .card-content > .flex
        height: 100%
      .field.iSwitch
        margin-bottom: 0
.card-permission
    width: 100%
    height: calc(100vh - 300px) !important
    overflow-y: auto
.permission
    height: 340px
    width: 100%
    @media only screen and (min-width: $bp-lg)
      width: calc(50% - 10px)
    @media only screen and (min-width: $bp-xxl)
      width: calc(33.333% - 10px)
</style>
