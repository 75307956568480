<template>
  <div id="block-settings">
    <Card>
      <MessageBadge
        :message="$t('blockSettings.messageBadge')"
        type="is-info"
        size="is-fit-content"
      />
      <div class="container-elements">
        <div class="table-container">
          <h5>{{ $t('blockSettings.tableTitle') }}</h5>
          <hr />
          <Table
            id="block-table"
            :loading.sync="loading.table"
            apiUrl="users/lockouts"
            :perPage="12"
            paginated
          >
            <b-table-column :label="$t('blockSettings.data')" v-slot="{ row }">
              <div>
                {{ row.blocked_until }}
              </div>
            </b-table-column>
            <b-table-column :label="$t('blockSettings.name')" v-slot="{ row }">
              <div>
                {{ row.full_name }}
              </div>
            </b-table-column>
            <b-table-column :label="$t('blockSettings.email')" v-slot="{ row }">
              <div>
                {{ row.email }}
              </div>
            </b-table-column>
          </Table>
        </div>
        <form ref="form" @submit.prevent="onSubmit" class="form">
          <div class="mb-4">
            <h5>{{ $t('blockSettings.blockPolicy') }}</h5>
            <Field
              :label="$t('blockSettings.maxAttempts')"
              type="Number"
              min="5"
              v-model="blockSettings.sign_in_attempts"
              :validationMessage="$t('blockSettings.validationMessageAttempts')"
              required
              :sufixText="$tc('global.attempt', 0)"
            />
            <Field
              :label="$t('blockSettings.waitingTime')"
              type="Number"
              min="1"
              v-model="blockSettings.blocked_user_minutes"
              :validationMessage="$t('blockSettings.validationMessageTime')"
              required
              :sufixText="$t('global.minutes')"
            />
          </div>
          <div class="mb-4">
            <h5>{{ $t('blockSettings.passPolicy') }}</h5>
            <Field
              label="Password expiration"
              type="Number"
              min="1"
              v-model="blockSettings.user_password_weeks_expire"
              :validationMessage="$t('blockSettings.validationMessageTime')"
              required
              :sufixText="$tc('global.week', 0)"
            />
            <Field
              label="Reminder for password expiration"
              type="Number"
              min="1"
              v-model="blockSettings.days_remaining_to_change_password"
              :validationMessage="$t('blockSettings.validationMessageTime')"
              required
              :sufixText="$tc('global.day', 0)"
            />
          </div>
          <div class="flex f-jce">
            <Button
              type="is-primary"
              :label="$t('button.save')"
              native-type="submit"
              :loading="loading.button"
            />
          </div>
        </form>
      </div>
    </Card>
  </div>
</template>
<script>
import { MessageBadge, Card, Table, Field, Button } from '@/components';
import { toast } from '@/utils/dialog';
export default {
  components: {
    Table,
    Card,
    Field,
    MessageBadge,
    Button
  },
  mounted() {
    this.blockSettings = this.settings;
  },
  data() {
    return {
      loading: { table: false, button: false },
      blockSettings: {}
    };
  },
  computed: {
    payload() {
      const BS = this.blockSettings;
      return {
        blocked_user_minutes: parseInt(BS.blocked_user_minutes),
        sign_in_attempts: parseInt(BS.sign_in_attempts),
        user_password_weeks_expire: parseInt(BS.user_password_weeks_expire),
        days_remaining_to_change_password: parseInt(BS.days_remaining_to_change_password)
      };
    }
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        this.loading.button = true;
        try {
          await this.Api.put('/system/settings', this.payload);
          toast('success', this.$t('messages.successfullyChanges'), 5000);
        } catch (error) {
          toast('error', this.$t('messages.errorChanges'), 5000);
        }
        this.loading.button = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  props: {
    settings: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#block-settings
  min-height: calc( 100vh - 200px ) !important
  .container-elements
    display: flex
  .table-container
    width: 100%
  h5
    margin-bottom: 16px
    font-weight: 700
  hr
    height: 1px
    margin: 0
    background-color: $gray-200
    margin-bottom: 24px
  .card
    margin: 0 auto
    padding: 0px
    .message-badge
      margin-bottom: 24px
  #block-table
    height: calc(100vh - 402px)
    ::v-deep
      .pagination
        margin-right: 0
      .table-wrapper
        min-height: 0
  .form
    border-radius: $br-md
    border: 1px solid $gray-200
    height: fit-content
    margin-left: 32px
    min-width: 300px
    max-width: 300px
    padding: 16px
@media screen and (max-width: $bp-lg)
  .container-elements
    flex-direction: column-reverse
  .form
    margin-left: 0 !important
    margin-bottom: 1rem
</style>
