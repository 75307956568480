<template>
  <div id="app-settings">
    <Card>
      <MessageBadge
        :message="$t('deliverySettings.messageBadge')"
        type="is-info"
        size="is-medium"
      />
      <form ref="form" @submit.prevent="onSubmit" class="form">
        <b-field grouped>
          <Field
            :label="$t('deliverySettings.taskDuration')"
            :sufixText="$tc('global.minutes', 0)"
            class="w-40"
            min="1"
            required
            type="number"
            v-model="oSettings.delivery_task_duration"
          />
          <Field
            :label="$t('deliverySettings.pickupTask')"
            :sufixText="$tc('global.minutes', 0)"
            class="w-20"
            min="1"
            required
            type="number"
            v-model="oSettings.pickup_task_duration"
          />
          <Field
            :label="$t('deliverySettings.speedAlert')"
            :validationMessage="$t('deliverySettings.valueOnKm')"
            class="w-20"
            required
            sufixText="km/h"
            type="number"
            v-model="oSettings.speed_alert"
          />
          <Autocomplete
            :label="$t('deliverySettings.defaultLenguage')"
            :data="languages"
            model="id"
            field="label"
            class="w-20"
            v-model="oSettings.default_user_language"
            required
          />
        </b-field>
        <b-field grouped>
          <Autocomplete
            :label="$t('deliverySettings.timeZone')"
            :api="{
              url: `/catalogs/timezone`,
              full: true
            }"
            model="name"
            field="name"
            class="w-40"
            v-model="oSettings.default_time_zone"
            required
          />
          <Field
            :label="$t('deliverySettings.refrigeratedEndTime')"
            :sufixText="$tc('global.minutes', 0)"
            class="w-20"
            min="1"
            required
            type="number"
            v-model="oSettings.default_refrigerated_end_time"
          />
          <Field
            :label="$t('deliverySettings.notificationsTime')"
            :sufixText="$tc('global.minutes', 0)"
            class="w-20"
            min="1"
            required
            type="number"
            v-model="oSettings.max_time_on_notifications"
          />
          <Field
            :label="$t('deliverySettings.notificationsAttempts')"
            :sufixText="$tc('global.attempt', 0)"
            class="w-20"
            min="1"
            required
            type="number"
            v-model="oSettings.max_attempts_on_notifications"
          />
        </b-field>
        <b-field grouped>
          <Field
            :label="$t('deliverySettings.lunchBreakDuration')"
            :sufixText="$tc('global.minutes', 0)"
            class="w-20"
            min="1"
            required
            type="number"
            v-model="oSettings.lunch_break_duration"
          />
          <Autocomplete
            :label="$t('deliverySettings.stateInForms')"
            class="w-20"
            :api="{
              url: 'catalogs/states',
              full: true
            }"
            v-model="defaultState"
            :validationMessage="$t('address.state')"
            field="code"
            model="code"
            storeResults
            autocomplete="no"
            required
          />
          <Field
            :label="$t('deliverySettings.geofence.ratio')"
            class="w-20"
            required
            sufixText="ft"
            type="number"
            min="1"
            :value="geofenceInFt"
            @input="onGeofenceChanged"
          />
        </b-field>
      </form>
      <template #footer>
        <hr />
        <div class="saveButton flex">
          <Button
            type="is-primary"
            :label="$t('button.save')"
            @click="onSubmit"
            :loading="loading"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { MessageBadge, Button, Card, Field, Autocomplete } from '@/components';
import { toast } from '@/utils/dialog';
export default {
  components: {
    Autocomplete,
    Button,
    Card,
    Field,
    MessageBadge
  },
  mounted() {
    this.oSettings = this.settings;
    this.geofenceInMetters = this.settings.geofence_size;
  },
  computed: {
    geofenceInFt() {
      return Math.round(this.oSettings.geofence_size * 3.28084);
    },
    languages() {
      return [
        { id: 'US', label: 'English' },
        { id: 'ES', label: 'Español' },
        { id: 'AR', label: 'عربى' }
      ];
    }
  },
  data() {
    return {
      geofenceInMetters: 0,
      loading: false,
      oSettings: {},
      defaultState: this.$store.getters.STATE,
      test: '0600'
    };
  },
  methods: {
    onGeofenceChanged(geofenceFt = 1) {
      this.geofenceInMetters = geofenceFt / 3.28084;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        try {
          if (this.oSettings.id) delete this.oSettings.id;
          await this.Api.put('/system/settings', {
            ...this.oSettings,
            geofence_size: this.geofenceInMetters,
            mechanic_emails: JSON.stringify(this.oSettings.mechanic_emails)
          });
          toast('success', this.$t('messages.saved'), 5000);
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  watch: {
    defaultState(value) {
      this.$store.dispatch('STATE', value);
    }
  },
  props: {
    settings: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#app-settings
  width: 100%
  min-height: calc( 100vh - 200px )
  .card
    min-height: calc( 100% - 200px ) !important
    margin: 0 auto
    padding: 0px
    .message-badge
      margin-bottom: 24px
    ::v-deep
      .field
        align-items: flex-end
    .form
      min-height: calc(100vh - 365px)
  .w-20
    width: calc(20% - 0.5rem)
  .w-40
    width: calc(40% - 0.75rem)
  hr
    margin: 0
    background-color: $gray-200
    margin-bottom: 24px
    justify-content: flex-end
  .saveButton
    justify-content: flex-end
    width: 100%

@media screen and (max-width: $bp-xxl)
  #app-settings
    .w-20
      width: calc(25% - 0.75rem)
    .w-40
      width: calc(26% - 0.75rem)
</style>
