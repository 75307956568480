<template>
  <section>
    <div id="roles-wrapper">
      <Portal to="headerActions">
        <Link
          to="/advanced-settings/roles-permissions/add"
          isRouter
          :permission="Permission.create"
        >
          <b-button
            type="is-primary"
            :label="$t('button.add', [$tc('role.rol.label').toLowerCase()])"
            icon-left="plus"
          />
        </Link>
      </Portal>
      <div class="roles flex">
        <Roles @click="onSelect" :userType="userType" />
        <Card
          :data="routes"
          :userType="userType"
          :loading="loading"
          :routesActive="routesActive"
          @setDataRol="setDataRol"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { Link } from '@/components';
import { Permissions } from '@/utils/Secure';
import Card from '@/components/AdvancedSettings/RolesPermissions/Card.vue';
import Roles from '@/components/AdvancedSettings/RolesPermissions/Roles.vue';

export default {
  components: {
    Roles,
    Card,
    Link
  },
  data() {
    return {
      loading: false,
      routes: {},
      routesActive: [],
      userType: {}
    };
  },
  computed: {
    Permission() {
      return Permissions.Rol_Permissions;
    }
  },
  mounted() {
    this.getRoutes();
  },
  methods: {
    async getRoutes() {
      const myRoutes = {};
      const { data } = await this.Api.get('/user_types/routes');
      data.map((element) => {
        const groupName = element.group_route.toLowerCase().replace(/ /g, `_`);
        if (!myRoutes[groupName]) myRoutes[groupName] = [];
        myRoutes[groupName] = [...myRoutes[groupName], element];
      });
      this.routes = myRoutes;
    },

    async onSelect(element) {
      this.loading = true;
      this.routesActive = [];
      const { data } = await this.Api.get(`user_types/${element.id}/routes`);
      data.map((e) => {
        this.routesActive = [...this.routesActive, e.route_id];
      });
      this.userType = element;
      this.loading = false;
    },

    setDataRol(data) {
      if (data.addElement) {
        this.routesActive = [...this.routesActive, data.value];
      } else {
        this.routesActive = this.routesActive.filter((element) => element !== data.value);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
#roles-wrapper
  .roles
    height: calc(100vh - 200px)
</style>
