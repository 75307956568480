<template>
  <div class="role-item flex f-ai" :class="activeClass ? 'active-class' : ''" @click="onClick">
    <b-icon :icon="icon" :style="style" />
    <p>{{ item.name }}</p>
  </div>
</template>
<script>
export default {
  computed: {
    style() {
      return {
        color: this.iconColor
      };
    }
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    onClick() {
      this.active = true;
      this.$emit('click', this.item);
    }
  },
  props: {
    activeClass: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      default: 'account-circle'
    },
    iconColor: {
      type: String,
      default: '#3D4FD2'
    }
  }
};
</script>

<style lang="sass" scoped>
.role-item
  border: 1px solid $gray-300
  background-color: white
  border-radius: 8px
  cursor: pointer
  padding: 1rem
  margin: 1rem 0
  width: 95%
  min-height: 50px
  p
    font-weight: bold
    padding-left: 0.75rem
  &:hover
    background-color: $gray-50
    box-shadow: 0 0 10px 1px darken($main-background,5)
.active-class
  background-color: $gray-50
  box-shadow: 0 0 10px 1px darken($main-background,5)
</style>
