<template>
  <div id="vr-email-settings">
    <Portal to="headerActions">
      <Button type="is-primary" :label="$t('global.newEmail')" icon-left="plus" @click="addEmail" />
    </Portal>
    <Card>
      <MessageBadge :message="$t('virEmailSettings.messageBadge')" type="is-info" size="is-large" />
      <Empty v-if="emails.length === 0" />
      <form v-else ref="form" @submit.prevent="onSubmit" class="form">
        <Field
          v-for="(email, i) in emails"
          :key="`mechanigEmail-${i}`"
          :label="$t('contact.email')"
          class="w-30"
          required
          v-model="emails[i]"
          type="email"
          :validation-message="$t('validations.email')"
          :upper="false"
          :useActionBtn="true"
          :btnAction="btnActionConfig"
          @actionClick="() => removeEmail(i)"
        />
      </form>
      <template #footer>
        <hr />
        <div class="saveButton flex">
          <Button
            type="is-primary"
            :label="$t('button.save')"
            @click="onSubmit"
            :loading="loading"
            :disabled="emails.length === 0"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { MessageBadge, Button, Card, Field, Empty } from '@/components';
import { toast } from '@/utils/dialog';
export default {
  components: {
    Button,
    Card,
    Field,
    MessageBadge,
    Empty
  },
  mounted() {
    this.emails = this.settings.mechanic_emails || [];
  },
  data() {
    return {
      loading: false,
      oSettings: this.settings,
      emails: this.settings.mechanic_emails || [],
      btnActionConfig: {
        label: this.$t('button.delete'),
        icon: 'close',
        type: 'is-danger',
        needsTooltip: false
      }
    };
  },
  methods: {
    addEmail() {
      this.emails.push('');
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        try {
          const data = {
            ...this.settings,
            mechanic_emails: JSON.stringify(this.emails)
          };
          delete data.id;
          await this.Api.put('/system/settings', data);
          toast('success', this.$t('messages.saved'), 5000);
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  props: {
    settings: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#vr-email-settings
  min-height: calc( 100vh - 200px ) !important
  .card
    min-height: calc( 100% - 200px ) !important
    margin: 0 auto
    padding: 0px
    .empty, .form
      min-height: calc(100vh - 365px)
    .message-badge
      margin-bottom: 24px
    ::v-deep
      .field
        align-items: flex-end
  .w-30
    width: calc(30% - 0.75rem)
  .saveButton
    justify-content: flex-end
    width: 100%
</style>
